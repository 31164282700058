import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Navbar from '../components/Navbar';
import Section from '../components/Section';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="¡Página inexistente!" />
      <Navbar />
      <Section
        sectionClass="uk-section-large"
        containerClass="uk-container-xsmall"
        className="uk-text-center"
      >
        <h1>¡La página que estás buscando no existe!</h1>
        <p>
          Puedes volver al inicio del sitio haciendo click{' '}
          <Link to="/">aquí</Link>.
        </p>
      </Section>
    </Layout>
  );
}

export default NotFoundPage;
